import { StaticImage } from "gatsby-plugin-image"
import React from "react"
// import moment from "moment"
import Layout from "./Layout"
import { graphql } from "gatsby"
import PostContentBody from "./PostContentBody"
// import { buildImageObj } from "../utils/helpers";
// import { imageUrlFor } from "../utils/image-url";
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import SEO from "./SEO";


  export const query = graphql`
    query BlogPostTemplateQuery($slug: String!) {
      post: sanityPost(slug: { current: { eq: $slug } }) {
        id
        publishedAt
        categories {
          _id
          title
        }
        title
        slug {
          current
        }
        _rawBody
        mainImage {
          asset {
            url
          }
        }
      }
    }
  `


const BlogPost = (props) => {

  const { data, errors, params } = props

    if (errors) {

    }


    const image = getImage(data && data.post.mainImage.asset.url)
  return (
    <Layout>
      <SEO />
      <div className="bg-gray-50">
        <StaticImage
          alt="insight driven media planning"
          src="https://res.cloudinary.com/appnet/image/upload/c_scale,q_100,w_9891/v1628681072/proscaenia/blog.png"
          placeholder="blurred"
          //   className="rounded-t-xl"
        />

        <div className="bg-gray-50">
          <div className="flex flex-col-reverse lg:flex-row px-8 lg:px-32">
            <article className="sanity-p flex flex-col w-full bg-white rounded-md -top-20 md:-top-48 xl:-top-64 px-4 lg:px-12 py-8 lg:py-16 relative">
              <h1 className="text-3xl lg:text-5xl pb-10 font-bold">
                {data && data.post.title}
              </h1>
              <img
                alt={data && data.post.title}
                src={data && data.post.mainImage.asset.url}
                placeholder="blurred"
                className="pb-16"
              />
              <PostContentBody
                portableTextContent={data && data.post._rawBody}
              />
            </article>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default BlogPost
